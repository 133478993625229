import { StoreInvoiceStatus } from '@/generated/typing';
import {
    Button,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Portal,
    Radio,
    RadioGroup,
} from '@chakra-ui/react';
import { HiBarsArrowDown } from 'react-icons/hi2';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'next/router';

export const ListStatusFilter = () => {
    const { t } = useTranslation(['storeInvoices', 'common']);
    const router = useRouter();
    const selectedValue = router.query['status'];

    const getOptionsFromStoreInvoicesStatus = (): {
        label: string;
        value: StoreInvoiceStatus;
    }[] => {
        return Object.values(StoreInvoiceStatus).map((value: StoreInvoiceStatus) => {
            return {
                label: t(`storeInvoices:status.${value}`),
                value: value,
            };
        });
    };

    const setStatus = (status: string | undefined) => {
        const query = { ...router.query };
        if (status !== undefined) query.status = status;
        else delete query.status;
        router.replace({ query }, undefined, { scroll: false });
    };

    return (
        <Menu>
            <Button as={MenuButton} leftIcon={<Icon as={HiBarsArrowDown} />}>
                {t('common:filter_by_status')}
            </Button>
            <Portal>
                <MenuList>
                    {getOptionsFromStoreInvoicesStatus().map((option) => (
                        <MenuItem key={option.value} onClick={() => setStatus(option.value)}>
                            <RadioGroup>
                                <Radio isChecked={selectedValue === option.value}>
                                    {option.label}
                                </Radio>
                            </RadioGroup>
                        </MenuItem>
                    ))}
                    <MenuItem>
                        <Button
                            onClick={() => setStatus(undefined)}
                            colorScheme="red"
                            size="xs"
                            disabled={!selectedValue}
                        >
                            {t('common:filter_clear')}
                        </Button>
                    </MenuItem>
                </MenuList>
            </Portal>
        </Menu>
    );
};

import { ColumnDef } from '@tanstack/react-table';
import SelectionTable from '../table/SelectionTable';
import Table from '../table/Table';
import { useListContext } from './ListContext';

interface ListResultsProps<TItem> {
    columns: ColumnDef<TItem, string>[];
    selection?: {
        selected: string[];
        onChange: (selected: string[]) => void;
        keyProperty: keyof TItem;
    };
    maxSelected?: number;
}

export const ListResults = <TItem extends { id: string }>({
    selection,
    columns,
    maxSelected,
}: ListResultsProps<TItem>) => {
    const { items } = useListContext();

    return selection ? (
        <SelectionTable<TItem>
            data={items as TItem[]}
            columns={columns}
            selected={selection.selected}
            onChange={selection.onChange}
            keyProperty={selection.keyProperty}
            maxSelected={maxSelected}
        />
    ) : (
        <Table<TItem> data={items as TItem[]} columns={columns} />
    );
};

import { PageDto, PageMetaDto, RequestParams } from '@/generated/typing';
import useToast from '@/hooks/useToast';
import { createGenericContext } from '@/utils/create-generic-context';
import { QueryKey, useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListQueryParams } from './types';

type ListContextValue = {
    items?: { id: string }[];
    pageMeta?: PageMetaDto;
    pageLabel: string;
    queryLabel: string;
    q: string;
    currentPage: number;
    section?: string;
};
type ListContextProps = {
    queryLabel?: string;
    children: ReactNode;
    queryCacheName: QueryKey;
    query: (
        query: ListQueryParams,
        params?: RequestParams,
    ) => Promise<PageDto & { data?: { id: string }[] }>;
    section?: string;
};
export const [useListContext, ListContextProvider] = createGenericContext<ListContextValue>();
export const ListProvider = ({
    children,
    query,
    queryCacheName,
    queryLabel = 'q',
}: ListContextProps) => {
    const router = useRouter();
    const toast = useToast();
    const { t } = useTranslation('common');
    const pageLabel = useMemo(
        () => (queryLabel === 'q' ? 'page' : `${queryLabel}-page`),
        [queryLabel],
    );
    const currentPage = useMemo(
        () => (router.query[pageLabel] ? Number(router.query[pageLabel]) : 1),
        [pageLabel, router.query],
    );
    const from = useMemo(
        () => (router.query.from ? new Date(router.query.from as string) : undefined),
        [router.query.from],
    );
    const to = useMemo(
        () => (router.query.to ? new Date(router.query.to as string) : undefined),
        [router.query.to],
    );
    const q = useMemo(
        () => (router.query[queryLabel] ? (router.query[queryLabel] as string) : ''),
        [queryLabel, router.query],
    );
    const status = useMemo(
        () => (router.query.status ? (router.query.status as string | string[]) : undefined),
        [router.query.status],
    );
    const section = useMemo(
        () => (router.query.section ? (router.query.section as string) : undefined),
        [router.query.section],
    );

    const { data } = useQuery(
        [
            ...(Array.isArray(queryCacheName) ? queryCacheName : [queryCacheName]),
            { page: currentPage, from, to, q, status },
        ],
        async () =>
            await query({
                page: currentPage,
                from: from?.toISOString(),
                to: to?.toISOString(),
                q: q?.length ? q : undefined,
                status: status ? [...(Array.isArray(status) ? status : [status])] : undefined,
                section,
            }),
        {
            keepPreviousData: true,
            onError: () => {
                toast({
                    title: t('fetching_data_error'),
                    status: 'error',
                });
            },
        },
    );
    return (
        <ListContextProvider
            value={{
                items: data?.data,
                pageMeta: data?.meta,
                pageLabel,
                queryLabel,
                q,
                currentPage,
                section,
            }}
        >
            {children}
        </ListContextProvider>
    );
};
